export function initMenuToggle() {
  const body = document.body
  body.addEventListener("click", (e) => {
    const target = e.target && e.target.closest("[data-menu-toggle]")
    if (target) {
      e.preventDefault()
      target.toggleAttribute("aria-expanded")
      body.classList.toggle("menu-is-open")
      if (body.classList.contains("menu-is-open")) {
        window.addEventListener("resize", () => {
          target.setAttribute("aria-expanded", false)
          body.classList.remove("menu-is-open")
        })
      }
    }
  })
}

export function initRegionNav() {
  const body = document.body
  const contentContainer = document.querySelector(".content")
  const toggleContainer = document.querySelector(".region-nav-toggle-container")
  const toggleOpen = document.querySelectorAll(".region-nav-toggle-open")
  const toggleClose = document.querySelector(".region-nav-toggle-close")
  const regionNav = document.querySelector(".region-nav-container")

  function openNav() {
    regionNav.setAttribute("aria-expanded", "true")
    body.classList.remove("region-nav-is-closed")
    if (!body.classList.contains("region-nav-is-open"))
      body.classList.add("region-nav-is-open")
  }

  function closeNav() {
    regionNav.setAttribute("aria-expanded", "false")
    body.classList.remove("region-nav-is-open")
    if (!body.classList.contains("region-nav-is-closed"))
      body.classList.add("region-nav-is-closed")
  }

  function setToggleContainerHeight(contentHeight) {
    toggleContainer.style.height = `${contentHeight}px`
  }

  if (regionNav) {
    const links = regionNav.querySelectorAll(".menu-item")
    setToggleContainerHeight(contentContainer.getBoundingClientRect().height)

    const contentContainerResizeObserver = new ResizeObserver((entries) => {
      entries.forEach((container) => {
        setToggleContainerHeight(container.contentRect.height)
      })
    })
    contentContainerResizeObserver.observe(contentContainer)

    toggleOpen.forEach((toggle) => {
      toggle.addEventListener("click", openNav)
    })
    toggleClose.addEventListener("click", closeNav)

    body.addEventListener("keydown", (e) => {
      if (e.key === "Escape" && body.classList.contains("region-nav-is-open")) {
        closeNav()
      }
    })

    links.forEach((link) => {
      link.addEventListener("click", (e) => {
        e.preventDefault()
        const link = e.target
        const group = link.parentElement.dataset.group
        const groupDetails = document.querySelector(
          `details[data-group="${group}"]`,
        )
        if (!groupDetails.open) {
          groupDetails.open = true
          setTimeout(() => {
            window.location.href = link.href
          }, 500)
        } else {
          window.location.href = link.href
        }
      })
    })
  }
}
