import { qsa, qs } from "./utils.js"

export function initForms() {
  function autoResize() {
    this.style.height = "auto"
    this.style.height = `${this.scrollHeight}px`
  }

  const textAreaFields = qsa("[data-textarea-autoresize] textarea")
  textAreaFields.forEach((textarea) => {
    textarea.addEventListener("input", autoResize, false)
  })

  qsa(".widget--radioselect input").forEach((input) => {
    input.parentElement.insertAdjacentElement("beforebegin", input)
    if (input.checked) input.dataset.check = true

    // radio-checkbox once again
    input.addEventListener(
      "click",
      (e) => {
        const input = e.target
        if (input.dataset.check == "true") {
          input.checked = false
          input.dataset.check = false
          groupUpdateScroller(input.closest(".group__container"))
        } else {
          input.dataset.check = true
          qsa("input", input.closest(".goal__fulfillment")).forEach(
            (otherInput) => {
              if (
                otherInput !== input &&
                !otherInput.checked &&
                otherInput.dataset.check == "true"
              )
                otherInput.dataset.check = false
            },
          )
        }
      },
      false,
    )
  })

  document.body.addEventListener("change", (e) => {
    let group
    const t = e.target
    if (
      t.matches('input[type="radio"]') &&
      (group = t.closest(".group__container"))
    ) {
      groupUpdateScroller(group)
    }
  })

  qsa(".group__container").forEach(groupUpdateScroller)
}

function groupUpdateScroller(group) {
  const dataGroup = group.querySelector("details")?.dataset.group
  const groupInputsTotal = qsa(".widget--radioselect", group).length
  const groupInputs = []
  qsa('input[type="radio"]:checked', group).forEach((input) => {
    if (input.value) groupInputs.push([input.value])
  })

  const scrollers = qsa(`[data-scroll-group="${dataGroup}"]`)

  scrollers.forEach((scroller) =>
    updateScroller(scroller, groupInputs, groupInputsTotal),
  )

  const overviewScroller = qs('[data-scroll-group="all"]')
  if (overviewScroller)
    updateOverviewScroller(
      overviewScroller,
      dataGroup,
      groupInputs,
      groupInputsTotal,
    )
}

function updateScroller(scroller, groupInputs, groupInputsTotal) {
  const listitems = qsa(".scroller__goal-properties li", scroller)
  if (listitems)
    listitems.forEach((li) => {
      li.dataset.count = groupInputs.filter(
        (input) => input == li.dataset.property,
      ).length
    })

  const pies = qsa(".pie", scroller)
  let nextOffset = 0
  const d = 200
  pies.forEach((pie) => {
    const offset = nextOffset
    const parts = groupInputs.filter(
      (input) => input == pie.dataset.property,
    ).length
    const size = ((Math.PI * d) / groupInputsTotal) * parts

    pie.style = `--pie-offset: ${offset}; --pie-size:${size}`
    nextOffset += size
  })
}

window.scroller_group_data = {}

function updateOverviewScroller(
  scroller,
  dataGroup,
  groupInputs,
  groupInputsTotal,
) {
  window.scroller_group_data[dataGroup] = {
    groupInputs,
    groupInputsTotal,
  }
  const scroller_group_data_array = Object.values(window.scroller_group_data)
  const allGroupInputs = scroller_group_data_array.reduce(
    (a, c) => a.concat(c.groupInputs),
    [],
  )
  const allGroupInputTotals = scroller_group_data_array.reduce(
    (a, c) => a + c.groupInputsTotal,
    0,
  )
  updateScroller(scroller, allGroupInputs, allGroupInputTotals)
}

export const initReportScroller = () => {
  const scroller = qs(".report .scroller")

  if (scroller) {
    const inputs = Array.prototype.slice.call(
      qsa(".report__goal__details--control"),
    )
    const groupInputs = inputs.map((details) => details.dataset.property)
    const groupInputsTotal = inputs.length

    updateScroller(scroller, groupInputs, groupInputsTotal)
  }
}
