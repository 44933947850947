import "./reset.css"
import "./grid.css"
import "./flow.css"
import "./functional.css"

import "./main.css"
import "./typography.css"
import "./details.css"
import "./footer.css"
import "./forms.css"
import "./scroller.css"
import "./report.css"
import "./header.css"
import "./navigation.css"
import "./messages.css"
import "./groups-and-goals.css"
import "./region-nav.css"

import { initDetails } from "./details.js"
import { initForms, initReportScroller } from "./forms.js"
import { initMenuToggle, initRegionNav } from "./navigation.js"
import { onReady } from "./utils.js"

onReady(() => {
  initDetails("details")
})
onReady(initMenuToggle)
onReady(initRegionNav)
onReady(initForms)
onReady(initReportScroller)
